import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

import HomePage from './pages/HomePage/HomePage';
import ProjectsPage from './pages/ProjectsPage/ProjectsPage';

import './App.css';

const App = () => {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route exact path='/' element={<HomePage/>} />
        <Route exact path='/projects' element={<ProjectsPage/>} />
      </Routes>
      <Footer/>
    </div>
  ) 
}

export default App;
