import React from 'react';

import './FooterItem.css';

const FooterItem = ({ url, logo }) => {
    return (
        <a className='logo-link' href={url} target='_blank' rel='noreferrer'>{logo}</a>
    )
}

export default FooterItem;