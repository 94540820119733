import React from 'react';

import profileImage from '../../assets/images/profile.jpg';
import PageLayout from '../../components/PageLayout/PageLayout';
import useTitle from '../../hooks/useTitle';

import './HomePage.css';

const HomePage = () => {
    useTitle('Home');
    return (
        <PageLayout>
            <div className='homepage-container'>
                <h1>Hello, internet stranger!</h1>
                <img className='profile-image' alt='Ben Cao' src={profileImage} />
                <p>My name is Ben Cao and welcome to my website!</p>
                <p>I am a web/software developer focused on using HTML, CSS, JavaScript, and React.
                    I also have experience with Node.js and PostgreSQL.
                    I like to take data and make visuals from them so people can absorb information in a presentable manner.
                    I've managed small and large scale projects and architected processes for teams, which I believe helps augment my development skills.
                </p>
                <p>I like to run, when my body decides to cooperate. I've been running since 2006. I also enjoy playing video games, reading, watching TV/movies, sleeping, eating, exploring, and so much more!</p>
                <p>I'm always trying to learn new things. I love to ask questions, even if it's an easy answer. You can never have enough information.</p>
                <p>Thanks for coming by and feel free to reach out!</p>
            </div>
        </PageLayout>
    )
}

export default HomePage;