import React from 'react';

import './ProjectCard.css';

const ProjectCard = ({ title, description, website, date }) => {
    return (
        <div className='project-card'>
            <h2 className='project-title'>{title}</h2>
            <h5 className='project-date'>{date}</h5>
            {
                website &&
                <a className='project-website' href={website} target='_blank' rel='noreferrer'>Live Site</a>
            }
            <p className='project-description' style={{whiteSpace: 'pre-wrap'}}>{description}</p>
        </div>
    )
}

export default ProjectCard;