import React from 'react';

import PageLayout from '../../components/PageLayout/PageLayout';
import ProjectCard from '../../components/ProjectCard/ProjectCard';
import useTitle from '../../hooks/useTitle';

import { projectData } from './projectData';

import './ProjectsPage.css';

const ProjectsPage = () => {
    useTitle('Projects');
    return(
        <PageLayout>
            <div className='projects-container'>
                {
                    projectData.map(project => {
                        return (
                            <ProjectCard 
                                key={project.id}
                                {...project}
                            />
                        )
                    })
                }
            </div>
        </PageLayout>
    )
}

export default ProjectsPage;