import React from 'react';

import FooterItem from '../FooterItem/FooterItem';

import { footerData } from './footerData';

import './Footer.css';

const Footer = () => {
    return (
        <div className='footer-container'>
            <div className='footer-content'>
                <div className='footer-item-container'>
                    {
                        footerData.map(item => {
                            return (
                                <FooterItem 
                                    key={item.id}
                                    url={item.url}
                                    logo={item.logo}
                                />
                            )
                        })
                    }
                </div>
                <div className='footer-copyright'><span>&copy;{(new Date().getFullYear())}</span></div>
            </div>
        </div>
    )
}

export default Footer;