export const projectData = [
    {
        id: 7,
        title: 'Infinity Scroll',
        description: 'A page utilizing HTML, CSS, and JavaScript to make an asynchronous request using the Unsplash API to display an infinite scroll of random images.',
        website: 'https://caofontaine.github.io/infinity-scroll/',
        date: '2021'
    },
    {
        id: 6,
        title: 'Quote Generator',
        description: 'A page utilizing HTML, CSS, and JavaScript making an synchronous fetch call to an API that will pull and array of quotes. The quote are then stored in a array and then randomly selected to show on the page. User will have the option to generate a new quote, or tweet the quote to Twitter.',
        website: 'https://caofontaine.github.io/quote-generator/',
        date: '2021'
    },
    {
        id: 5,
        title: 'React Dex',
        description: 'Web application built with React displaying a Pokèdex from the Pokèmon series. Allows the ability to view Pokèmon (up to 151) and see normal and shiny forms.\n\nStack includes: Node.js, Express.js, PostgreSQL, React, HTML, CSS, JavaScript.\nTesting includes unit testing in frontend and backend using Jest and Enzyme/Supertest.\nApplication hosted on Heroku.',
        website: 'https://react-dex-ben-2021.herokuapp.com/',
        date: '2021'
    },
    {
        id: 4,
        title: 'See Yo Cheevos',
        description: 'Using the X API, this allows the application to search for a user\'s game achievements on the Xbox platform, and displays information such as the game name and the gamerscore earned out of the total possible gamerscore for the game, in addition to display a bar representing the game\'s completion percentage in relation to gamerscore earned.\n\nUsing the free version of X API, the application is limited to 60 calls per hour. A search will take up 3 calls, looking for the user ID, then obtaining the Xbox 360 and Xbox One achievements for a particular user in 2 separate calls.\n\nTo test, use "AfroBovice" as the input.',
        website: 'https://caofontaine.github.io/see-yo-cheevos/',
        date: '2021'
    },
    {
        id: 3,
        title: 'Smart Brain',
        description: 'A final project putting together lessons learned in full-stack web development. This allows a user to create an account and input an image URL. Using the Clarifai face detect API, if the image has a face in it, it will detect the first face it sees (should the image have multiple faces). The user\'s count of successful face detections will also be tracked.\n\nStack includes: Node.js, Express.js, PostgreSQL, React, HTML, CSS, JavaScript.\nApplication hosted on Heroku.',
        website: 'https://smart-brain-ben-2020.herokuapp.com/',
        date: '2021'
    },
    {
        id: 2,
        title: 'BCaoFitbit',
        description: 'This project was designed to serve as a aggregate of the continuation of learning I have done with web development technologies and a challenge for myself to see the fruits of my labor.\n\nOne of the things that interests me the most is being able to take data from some place and make it visually appealing.\n\nThe data was taken from my own Fitbit, focusing on the step data. From there, the data was broken down to show the number of steps taken for a year or a certain month in a year. In addition to that, since Fitbit allows you to set a goal for total number of steps you take each day, I mapped out a total of days hit or miss of my specified step goal.\n\nUsing the MEAN stack (MongoDB, Express.js, AngularJS, Node.js), in conjunction with Google Charts, this was achieved.',
        website: 'https://github.com/caofontaine/BCaoFitbit',
        date: '2016-2017'
    },
    {
        id: 1,
        title: 'bcao.me',
        description: 'This website was created in 2014, using a LAMP stack in conjunction with HTML, CSS, PHP, and Bootstrap, hosted on DigitalOcean. It has evolved into a React SPA in 2021, continuing its purpose of being a constant space to show off my work and branch out into the world of the internet.',
        website: 'https://www.bcao.me',
        date: '2014 - present'
    }
];