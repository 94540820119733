import React from 'react';

import './PageLayout.css';

const PageLayout = (props) => {
    return (
        <div className='page-layout'>
            {props.children}
        </div>
    )
}

export default PageLayout;