import { ReactComponent as XLogo } from '../../assets/glyphs/x.svg';
import { ReactComponent as InstagramLogo } from '../../assets/glyphs/instagram.svg';
import { ReactComponent as LinkedInLogo } from '../../assets/glyphs/linkedin.svg';
import { ReactComponent as GitHubLogo } from '../../assets/glyphs/github.svg';
import { ReactComponent as BlogLogo } from '../../assets/glyphs/blog.svg';
import { ReactComponent as MailLogo } from '../../assets/glyphs/mail.svg';

export const footerData = [
    {
        id: 1,
        url: 'https://www.twitter.com/caofontaine',
        logo: <XLogo className='glyph' />
    },
    {
        id: 2,
        url: 'https://www.instagram.com/benjcao',
        logo: <InstagramLogo className='glyph' />
    },
    {
        id: 3,
        url: 'https://www.linkedin.com/in/benjcao',
        logo: <LinkedInLogo className='glyph' />
    },
    {
        id: 4,
        url: 'https://www.github.com/caofontaine',
        logo: <GitHubLogo className='glyph' />
    },
    {
        id: 5,
        url: 'https://caofontaine.blogspot.com',
        logo: <BlogLogo className='glyph' />
    },
    {
        id: 6,
        url: 'mailto:bencao@bcao.me',
        logo: <MailLogo className='glyph' />
    }
];